/* 
   VERY IMPORTANT: This file is shared by the iframe and portal.
   VERY IMPORTANT: The master copy of this file lives at civic-pass/shared/.
   VERY IMPORTANT: Make all changes there, then run yarn copyAll to copy it to the correct locations.
*/

/* colors must be added as rgb and not hex */
.blue-theme {
  --color-primary: 255, 255, 255;   /* white */
  --color-secondary: 52, 104, 135;  /* civicBlue */
  --color-tertiary: 170, 219, 255;  /* civicBlue-light */
  --color-quaternary: 53, 133, 191; /* civicBlue-dark */

  --color-primary-text: 255, 255, 255;   /* white */
  --color-secondary-text: 170, 219, 255; /* civicBlue-light */
  --color-tertiary-text: 229, 244, 255;  /* civicBlue-lightest */
  --color-quaternary-text: 53, 133, 191; /* civicBlue-dark */

  --color-primary-border: 53, 133, 191;    /* civicBlue-dark */
  --color-secondary-border: 255, 255, 255; /* white */

  --color-base: 255, 255, 255;  /* white */
  --color-base-text: 0, 0, 0;
  --color-base-border: 0, 0, 0;

  --color-info: 170, 219, 255;       /* civicBlue-light */
  --color-info-text: 0, 0, 0;
  --color-info-border: 53, 133, 191; /* civicBlue-dark */

  --color-error: 255, 0, 0;        /* red */
  --color-error-text: 255, 0, 0;   /* red */
  --color-error-border: 255, 0, 0; /* red */

  --color-warning: 252,255,97;        /* red */
  --color-warning-text: 252,255,97;   /* civicYellow */
  --color-warning-border: 252,255,97; /* civicYellow */

  --color-inactive: 153, 153, 153;      /* civicGray */
  --color-inactive-text: 153, 153, 153; /* civicGray */
  --color-inactive-border: 0, 0, 0;

  --color-text-placeholder: 153, 153, 153; /* civicGray */

  --color-link-text: 3, 133, 191; /* civicBlue-dark */

  /* Button colours */
  --color-button-background: 255, 255, 255;         /* white */
  --color-button-background-hover: 238, 244, 254;   /* civicLightGray-light */
  --color-button-background-disabled: 156,163,175;  /* gray-400 */
  --color-button-background-active: 223, 231, 243;  /* Button pressed state - civicLightGray-lightish */

  /* Icon colours */
  --color-icon-primary: 170, 219, 255; /* Icon dark */
  --color-icon-secondary: 170, 219, 255; /* Icon dark */

  --font-family-sans: "Inter";

  --default-background: radial-gradient(121.98% 156.84% at 0.59% 0.95%, #346887 0%, #221e42 100%);
  --mobile-background: linear-gradient(90deg, #305778 0%, #221e42 100%);

  /* Facetec colour */
  --color-facetec-primary: #325E7E;
  --color-facetec-secondary: #346987;
  --color-facetec-text: #1f3b73;

  /* Onfido colours */
  --color-onfido-primary: #ffffff;
  --color-onfido-secondary: #ffffff;
  --color-onfido-text: #3586bf;
  --color-onfido-title: #ffffff;
}

.blue-theme h1 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: rgb(var(--color-tertiary));
}

.blue-theme h2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: rgb(var(--color-tertiary));
}

.blue-theme h3 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: rgb(var(--color-tertiary));
}

.blue-theme h4 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: rgb(var(--color-tertiary));
}

.blue-theme h5 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  color: rgb(var(--color-primary));
}

.blue-theme p {
  color: rgb(var(--color-primary-text));
}

.blue-theme .textDetail {
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: rgb(var(--color-primary-text));
}

.blue-theme .textFooter {
  color: rgb(var(--color-primary-text));
}

.blue-theme .textLink {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: rgb(var(--color-secondary-text));
}
.blue-theme .textLink:hover {
  color: rgb(var(--color-primary-text));
}

.blue-theme .textLinkDetail {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  color: rgb(var(--color-secondary-text));
}
.blue-theme .textLink:hover {
  color: rgb(var(--color-primary-text));
}

.blue-theme .buttonDefault {
  background-color: rgb(var(--color-button-background));
  color: rgb(var(--color-quaternary-text));
  border-radius: 9999px;
  width: 50%;
  min-width: 100%;

  border-width: 2px;
  border-color: rgba(0, 0, 0, 0);
}

.blue-theme .buttonDefault:hover {
  background-color: rgb(var(--color-button-background-hover));
}

.blue-theme .buttonDefault:disabled {
  background-color: rgb(var(--color-button-background-disabled));
}

.blue-theme .buttonDefault:active {
  background-color: rgb(var(--color-button-background-active));
}

.blue-theme .buttonRound {
  background-color: transparent;
  color: rgb(var(--color-primary-text));
  border-radius: 9999px;
  border-width: 2px;
  border-color: rgb(var(--color-secondary-border));
}

.blue-theme .buttonRound:hover {
  background-color: rgba(var(--color-button-background-hover), 0.15);;
}

.blue-theme .buttonRound:disabled {
  background-color: rgb(var(--color-button-background-disabled));
}

.blue-theme .buttonRound:active {
  background-color: rgb(var(--color-button-background-active));
}

.blue-theme .buttonLink {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  background: none!important;
  border: none;
  padding: 0!important;
  cursor: pointer;
  color: rgb(var(--color-secondary-));
}

.blue-theme .buttonLink:hover {
  color: rgb(var(--color-primary-));
}

.blue-theme body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Old blue colour definitions */
/* colors: {
  civicBlue: {
    lightest: '#E5F4FF',
    light: '#AADBFF',
    lighter: '#53A5E1',
    DEFAULT: '#346887',
    dark: '#3585BF',
    darkest: '#221E42',
    darkHighlight: '#1E72BA',
  },
  civicLightGray: {
    lightest: '#FFFFFF',
    lighter: '#F3F3F3',
    light: '#EEF4FE',
    lightish: '#DFE7F3',
    DEFAULT: '#ECECEC',
  },
  civicGray: {
    DEFAULT: '#999999'
  },
  civicYellow: {
    DEFAULT: '#FCFF61',
  },
  civicTransparent: {
    DEFAULT: '#FFFFFFFF',
    15: '#FFFFFF26'
  }
}, */