@import 'theme-main.css';
@import 'theme-blue.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* global styles */

#root {
  display: flex;
  justify-content: center;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #f9fafb;
}

.main-container {
  width: 100vw;
}

.delete-token-panel {
  width: 450px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  border-radius: 32px;
}

.connect-button {
  background-color: rgb(var(--color-primary));
  color: rgb(var(--color-base));
  border-radius: 9999px;
  border: 2px solid rgba(0, 0, 0, 0);
  padding: 0.8rem 1rem;
  font-size: 14px;
}

.connect-button:hover {
  background-color: rgb(var(--color-base));
  border: 2px solid rgb(var(--color-button-background));
  color: rgba(0, 0, 0, 1);
}

span.connect-button-positioner button.wallet-adapter-button.wallet-adapter-button:hover {
  background: rgb(var(--color-base), 1);
  color: rgba(0, 0, 0, 0);
}

span.connect-button-positioner button.wallet-adapter-button.wallet-adapter-button {
  border: 1px solid rgb(var(--color-base), 1);
  box-sizing: border-box;
  border-radius: 50px;
  height: 50px;
  font-family: Aventa;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.41px;
  color: rgb(var(--color-base));
  background-color: unset;
}

.connect-button-positioner {
  float: right;
  height: 30px;
  margin-top: -15px;
  margin-right: 10px;
}

.top-panel-buttons {
  float: right;
}

.main-logo-positioner {
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  display: block;
  width: 100%;
  height: 50px;
}

.store-buttons-panel {
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  width: 300px;
  padding: 10px;
  display: flex;
}

.wallet-connection-image {
  width: 211px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.wallet-connection-button {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.wallet-connection-description {
  width: 371px;
  margin-left: auto;
  margin-right: auto;
}

.wallet-connection-ruler {
  background: rgb(var(--color-primary));
  width: 100%;
  height: 1px;
}

.wallet-connection-dapps-panel {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  width: 350px;
  font-family: 'Aventa';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: #ffffff;
}

@media screen and (max-width: 479px) {
  .documentation-panel {
    width: 100%;
    margin-top: 15px;
    border-radius: 0px;
  }

  .documentation-description {
    width: auto;
  }

  .wallet-connection-description {
    width: 100%;
  }

  .status-button {
    color: rgb(var(--color-base));
    box-sizing: border-box;
    border-radius: 50px;
    font-weight: 800;
    font-size: 15px;
    text-align: center;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
    min-width: 100px;
    height: 36px;
    display: flex;
    align-items: center;
    background-color: rgb(var(--color-button-background));
  }

  .wallet-connection-panel {
    padding-top: unset;
    width: 100%;
    border-radius: 0px;
    margin-top: 15px;
  }

  .connect-button-positioner {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 50%;
    height: 50px;
    float: none;
  }

  .main-logo-positioner {
    margin-top: 20px;
  }
}

@media screen and (min-width: 480px) {
  .documentation-panel {
    width: 450px;
    height: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    border-radius: 32px;
  }

  .documentation-description {
    width: auto;
  }

  .status-button {
    color: rgb(var(--color-base));
    box-sizing: border-box;
    border-radius: 50px;
    font-weight: 800;
    font-size: 15px;
    text-align: center;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
    width: 184px;
    height: 50px;
    display: flex;
    align-items: center;
    background-color: rgb(var(--color-button-background));
  }
}

.rounded-logo {
  width: 190px;
  height: 190px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 30px;
}

.rounded-logo-small {
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 30px;
}

.loading-spinner {
  border: 2px solid rgb(var(--color-secondary));
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 26px;
  height: 26px;
  box-sizing: border-box;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spin linear 1s infinite;
  animation-timing-function: cubic-bezier(0.15, 0.35, 0.81, 0.57);
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.status-button:hover {
  background: rgb(var(--color-base)) !important;
  border: none;
  color: rgb(var(--color-button-background));
  border: 2px solid rgb(var(--color-button-background));
}

.status-button.NOT_REQUESTED {
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  color: rgb(var(--color-base));
  background-color: rgb(var(--color-button-background));
  border-radius: 9999px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0);
}

.status-button span {
  line-height: 0px !important;
}

.status-button.NOT_REQUESTED:hover {
  background-color: rgb(var(--color-button-background-hover));
  color: rgb(var(--color-button-background));
  border-color: rgb(var(--color-primary));
}

.status-button.NOT_REQUESTED:active {
  background-color: rgb(var(--color-button-background-active));
  color: rgb(var(--color-button-background));
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  background-color: rgb(var(--color-error));
}

.dot.UNKNOWN {
  display: none;
}

.dot.ACTIVE {
  background-color: #05ff00;
}

.dot.NOT_REQUESTED {
  height: 0px;
  width: 0px;
  border-radius: 0%;
  display: none;
}

.documentation-button {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}

.multi-connect-button.ethereum button {
  color: rgb(var(--color-base));
  width: auto;
  height: auto;
  border: 2px solid transparent;
}

.multi-connect-button.solana button:hover {
  background: rgb(var(--color-base)) !important;
  border: 2px solid rgb(var(--color-button-background));
  color: rgb(var(--color-button-background));
}

.multi-connect-button button.NOT_REQUESTED {
  height: 36px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: rgb(var(--color-button-background));
  border-radius: 9999px;
  border-width: 2px;
  text-align: center;
  color: rgb(var(--color-base));
  border-color: rgba(0, 0, 0, 0);
}

.multi-connect-button button.NOT_REQUESTED:hover {
  background-color: rgb(var(--color-button-background-hover));
  border-color: rgb(var(--color-primary));
  color: rgb(var(--color-base));
}

.multi-connect-button button:focus {
  color: rgb(var(--color-base));
}

.multi-connect-button button.NOT_REQUESTED:active {
  background-color: rgb(var(--color-button-background-active));
  color: rgb(var(--color-button-background));
}
