/* 
   VERY IMPORTANT: This file is shared by the iframe and portal.
   VERY IMPORTANT: The master copy of this file lives at civic-pass/shared/.
   VERY IMPORTANT: Make all changes there, then run yarn copyAll to copy it to the correct locations.
*/

/* colors must be added as rgb and not hex */
.main-theme {
  --color-primary: 99, 102, 241; /* civic indigo */
  --color-primary-text: 47, 13, 48; /* civic dark */
  --color-primary-border: 99, 102, 241; /* civic indigo */

  --color-secondary: 47, 13, 48; /* civic dark */
  --color-secondary-text: 99, 102, 241; /* civic indigo */
  --color-secondary-border: 47, 13, 48; /* civic dark */

  --color-tertiary: 255, 168, 119; /* civic nude */
  --color-tertiary-text: 255, 96, 65; /* Icon dark */
  --color-tertiary-border: 153, 153, 153; /* civicGray */

  --color-quaternary: 255, 245, 118; /* civic yellow */
  --color-quaternary-text: 47, 13, 48; /* civic dark */
  --color-quaternary-border: 255, 96, 65; /* Icon dark */

  --color-base: 255, 255, 255; /* white */
  --color-base-text: 0, 0, 0;
  --color-base-border: 0, 0, 0;

  --color-info: 170, 219, 255; /* civicBlue-light */
  --color-info-text: 0, 0, 0;
  --color-info-border: 99, 102, 241; /* civic indigo */

  --color-error: 174, 20, 57; /* Delete/undo */
  --color-error-text: 174, 20, 57; /* Delete/undo */
  --color-error-border: 174, 20, 57; /* Delete/undo */

  --color-warning: 174, 20, 57; /* Delete/undo */
  --color-warning-text: 174, 20, 57; /* Delete/undo */
  --color-warning-border: 174, 20, 57; /* Delete/undo */

  --color-inactive: 153, 153, 153; /* civicGray */
  --color-inactive-text: 153, 153, 153; /* civicGray */
  --color-inactive-border: 0, 0, 0;

  --color-text-placeholder: 153, 153, 153; /* civicGray */

  --color-link-text: 255, 96, 65; /* Icon dark */

  /* Icon colours */
  --color-icon-primary: 255, 255, 255; /* white */
  --color-icon-secondary: 255, 96, 65; /* Icon dark */

  /* Button colours */
  --color-button-background: 99, 102, 241; /* civic indigo */
  --color-button-background-hover: transparent;
  --color-button-background-disabled: 99, 102, 241, 0.5; /* civic indigo 50% opacity */
  --color-button-background-active: 223, 231, 243; /* Button pressed state - civicLightGray-lightish */

  --font-family-sans: 'Aventa';

  --default-background: #ffffff;
  --mobile-background: #ffffff;

  /* Facetec colours */
  --color-facetec-primary: rgb(var(--color-primary));
  --color-facetec-secondary: rgb(var(--color-link-text));
  --color-facetec-text: rgb(var(--color-primary-text));

  /* Onfido colours */
  --color-onfido-primary: rgb(var(--color-primary));
  --color-onfido-secondary: rgb(var(--color-tertiary));
  --color-onfido-text: rgb(var(--color-primary-text));
  --color-onfido-title: rgb(var(--color-primary));
}

.main-theme {
  font-family: Aventa;
  color: rgb(var(--color-primary-text));
}

.main-theme h1 {
  font-size: 26px;
  line-height: 30px;
  font-weight: 600;
}

.main-theme h2 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
}

.main-theme p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

.main-theme .textDetail {
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
}

.main-theme .textFooter {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.main-theme .textLink {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: rgb(var(--color-tertiary-text));
}
.main-theme .textLink:hover {
  color: rgb(var(--color-base-text));
}

.main-theme .textLinkDetail {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  color: rgb(var(--color-tertiary-text));
}
.main-theme .textLinkDetail:hover {
  color: rgb(var(--color-base-text));
}

.main-theme .checkBox {
  color: rgb(var(--color-secondary));
  border-color: rgb(var(--color-primary-border));
}

.main-theme .checkBox:checked {
  color: rgb(var(--color-secondary-text));
  border-color: rgb(var(--color-button-background));
  background-color: rgb(var(--color-button-background));
}

.main-theme .checkBox:focus {
  outline: 2px solid rgba(1, 94, 204); /* This is the standard blue focus colour */
  outline-offset: 2px;
  box-shadow: none; /* Show focus ring when tabbing into checkbox */
}

.main-theme .checkBox:focus:not(:focus-visible) {
  outline: none; /* Don't show focus ring when clicking on checkbox */
  outline-offset: 0;
}

.main-theme .buttonDefault {
  background-color: rgb(var(--color-button-background));
  color: rgb(var(--color-primary-text));
  border-radius: 9999px;
  width: 50%;
  min-width: 100%;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0);
}

.main-theme .buttonDefault:hover {
  background-color: rgb(var(--color-button-background-hover));
  color: rgb(var(--color-base-text));
  border-color: rgb(var(--color-primary));
}

.main-theme .buttonDefault:disabled {
  color: rgba(var(--color-base-text), 0.5);
  background-color: rgba(var(--color-button-background-disabled));
  border-color: rgba(0, 0, 0, 0);
}

.main-theme .buttonDefault:active {
  background-color: rgb(var(--color-button-background-active));
}

.main-theme .buttonRound {
  background-color: transparent;
  color: rgb(var(--color-secondary-text));
  border-radius: 9999px;
  border-width: 2px;
  border-color: rgb(var(--color-primary-border));
}

.main-theme .buttonRound:hover {
  background-color: rgba(var(--color-primary), 0.15);
}

.main-theme .buttonRound:disabled {
  background-color: rgb(var(--color-button-background-disabled));
  color: rgba(var(--color-secondary-text), 0.95);
}

.main-theme .buttonLink {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  font-family: 'Aventa';
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  color: rgb(var(--color-tertiary-text));
}

.main-theme .buttonLink:hover {
  color: rgb(var(--color-base-text));
}

/*!
 * Web Fonts from Fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at https://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and 
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2022 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      Ellen Luff
 * License URL: https://www.fontspring.com/licenses/ellen-luff/webfont
 *
 *
 */

@font-face {
  font-family: 'Aventa';
  src: url('/src/common/assets/fonts/aventa-regular-webfont.woff2') format('woff2'),
    url('/src/common/assets/fonts/aventa-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Aventa';
  src: url('/src/common/assets/fonts/aventa-bold-webfont.woff2') format('woff2'),
    url('/src/common/assets/fonts/aventa-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Aventa';
  src: url('/src/common/assets/fonts/aventa-semibold-webfont.woff2') format('woff2'),
    url('/src/common/assets/fonts/aventa-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Aventa';
  src: url('/src/common/assets/fonts/aventa-itallic-webfont.woff2') format('woff2'),
    url('/src/common/assets/fonts/aventa-itallic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
